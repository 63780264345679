import React from 'react';
import ErrorPage from '../Components/errorPage';
import {graphql} from 'gatsby';
import {flattenQueryResults} from '../utils/utils';

export default ({data}) => {
  const content = flattenQueryResults(data.allMarkdownRemark)[0] || {};

  return <ErrorPage content={content.html} />;
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: {frontmatter: {id: {eq: "404"}}}) {
      edges {
        node {
          html
        }
      }
    }
  }
`;
